import './App.css';

function App() {
  return (
    <div className="App">
      <p className="title">Cummings Web Services</p>
      <p className="copyright">&#169; 2022 Cummings Web Services Limited</p>
    </div>
  );
}

export default App;
